<template>
  <article>

    <div
      v-show="showDetail"
      id="popupMedicalView"
      class="layerCommon medical-view-type"
      style="display: block"
      @click.self="closeDrugInfoModel"
    >
      <div
        class="layer-medical-detail"
        @click.self="closeDrugInfoModel"
      >
        <div
          class="medical-detail-wrap"
          @scroll.stop="scrollPopup"
        >
          <div class="layer-utility">
            <button
              class="btn-close"
              @click="closeDrugInfoModel"
            >
              close
            </button>
            <button
              v-if="drugInfo && drugInfo.isComment"
              class="btn-reply"
              @click="scrollToElement"
            >
              reply
            </button>
            <button
              class="btn-next"
              @click="moveNext(liveInfoId)"
            >
              next
            </button>
            <button
              class="btn-prev"
              @click="movePrev(liveInfoId)"
            >
              prev
            </button>
          </div>
          <div class="md-header">
            <dl>
              <dt>{{ drugInfo ? drugInfo.liveInfo.title : '' }}</dt>
              <dd v-if="drugInfo && drugInfo.liveInfo">
                {{ drugInfo.liveInfo.startDate | $dateFormatter('YYYY.MM.DD') }} {{ drugInfo.liveInfo.startDate ? `(${getDateString(drugInfo.liveInfo.startDate)})` : '' }}
              </dd>
            </dl>
            <div class="text-info">
              <span class="viewer">{{ drugInfo ? drugInfo.liveInfo.viewCount : 0 }}</span>
              <span
                v-if="drugInfo && drugInfo.isComment"
                class="reply"
              >{{ commentCount }}</span>
            </div>
          </div>

          <div
            v-if="drugInfo && drugInfo.drugInfoType == 1"
            class="md-contents"
          >
            <!-- html 영역 -->
            <div
              v-dompurify-html="drugInfo.liveInfo.htmlContent"
              class="content"
              @click="handleClick"
            />
          </div>

          <!-- tab 컨텐츠일 경우 -->
          <div
            v-if="drugInfo && drugInfo.drugInfoType == 2"
          >
            <div
              class="md-tab"
            >
              <button
                v-if="drugInfo && drugInfo.tabTitle1"
                id="tab1"
                :class="{ 'active' : isTabActive1}"
                @click="drugInfoTab1()"
              >
                <span>{{ drugInfo.tabTitle1 }}</span>
              </button>
              <button
                v-if="drugInfo && drugInfo.tabTitle2"
                id="tab2"
                :class="{ 'active' : isTabActive2}"
                @click="drugInfoTab2()"
              >
                <span>{{ drugInfo.tabTitle2 }}</span>
              </button>
              <button
                v-if="drugInfo && drugInfo.tabTitle3"
                id="tab3"
                :class="{ 'active' : isTabActive3}"
                @click="drugInfoTab3()"
              >
                <span>{{ drugInfo.tabTitle3 }}</span>
              </button>
            </div>
            <div
              v-if="tabType === 1"
              v-dompurify-html="tabContent"
              class="content"
              @click="tabHandleClick"
            />

            <div
              v-else-if="tabType === 2"
            >
              <iframe
                ref="iframe"
                :src="tabContent"
                frameborder="0"
                width="100%"
                style="height: 700px;"
              />
            </div>
          </div>

          <!-- 메디컬트렌드 연결배너 -->
          <div
            v-if="medicalFixBanner && medicalFixBanner[0] && medicalFixBanner[1]"
            class="ad-fix-banner"
          >
            <div class="fix-b-title">
              <span>추천 컨텐츠</span>심사청구, 처방 레시피 등 궁금증을 해결하는<strong style="margin: 2.5px 0px 0px 4px;">메디컬트렌드</strong>
            </div>

            <div class="fix-b-div2">

              <div class="banner01 medicalFixBanner">
                <a @click="$router.push({ name: 'app-medical-trend-main', query: { id: medicalFixBanner[0].liveInfoId, entry: 181 } })">
                  <img
                    :src="medicalFixBanner[0].filePath"
                    loading="lazy"
                    style="width:494px; height:253px;"
                  >
                </a>
              </div>

              <div class="banner02 medicalFixBanner">
                <a @click="$router.push({ name: 'app-medical-trend-main', query: { id: medicalFixBanner[1].liveInfoId, entry: 181 } })">
                  <img
                    :src="medicalFixBanner[1].filePath"
                    loading="lazy"
                    style="width:494px; height:253px;"
                  >
                </a>
              </div>

            </div>
          </div>

          <comment-form
            v-show="drugInfo && drugInfo.isComment"
            ref="commentForm"
            :content-type="1"
          />
        </div>

      </div>
    </div>
    <confirm-dialog :options="confirmProps" />
    <alert-dialog :options="alertProps" />
    <VisitCallPopup
      ref="visitCallRef"
      :show-modal="showVisitModal"
      :live-info-id="liveInfoId"
      @close="closeVisitModal"
    />
    <SampleCallPopup
      ref="sampleCallRef"
      :show-modal="showSampleModal"
      :live-info-id="liveInfoId"
      @close="closeSampleModal"
    />
    <EmailCallPopup
      ref="emailCallRef"
      :show-modal="showEmailModal"
      :live-info-id="liveInfoId"
      @close="closeEmailModal"
    />
  </article>
</template>
<script>
import axios from '@axios'
import { getUserData, isUserLoggedIn } from '@/auth/utils'
import { callExternalApi } from '@/core/global/global-liveinfo'
import { setStatistics } from '@/common/statistics/service'
import CommentForm from '../CommentForm.vue'
import VisitCallPopup from './VisitCallPopup.vue'
import SampleCallPopup from './SampleCallPopup.vue'
import EmailCallPopup from './EmailCallPopup.vue'

export default {
  components: {
    CommentForm,
    VisitCallPopup,
    SampleCallPopup,
    EmailCallPopup,
  },
  filters: {
    dayFormat(value) {
      const date = new Date(value)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')

      return `${year}.${month}.${day}`
    },
    timeFormat(value) {
      const date = new Date(value)
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')

      return `${hours}:${minutes}`
    },
  },
  props: {
    liveInfoIds: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      liveInfoId: 0,
      drugInfo: {
        liveInfo: [],
        isComment: false,
      },
      showDetail: false,
      medicalFixBanner: [],
      medicalBannerLogging: false,
      commentCount: 0,
      user: {},
      commentlist: [],
      comment: {
        commentId: 0,
        commentText: '',
        contentType: 2,
        contentId: 0,
        userId: 0,
        isEditing: false,
        editText: '',
        isReplyEditing: false,
        parentId: 0,
        replyEditText: '',
        isReplyModify: false,
        replyModifyText: '',
        isUserDelete: false,
      },
      commentId: null,
      contentType: 1, // 약품정보
      tabType: null,
      tabContent: '',
      isTabActive1: true,
      isTabActive2: false,
      isTabActive3: false,
      showVisitModal: false,
      showSampleModal: false,
      showEmailModal: false,
    }
  },
  mounted() {
    this.user = getUserData()
  },
  methods: {
    fetchDrugInfo(id) {
      axios.get(`/fu/druginfo/${id}`)
        .then(rs => {
          this.scrollTop()
          if (rs.data.item.externalLink === null || rs.data.item.externalLink.length === 0) {
            this.drugInfo = rs.data.item
            this.medicalFixBanner = rs.data.banner

            this.commentCount = rs.data.commentCount

            this.tabType = this.drugInfo.tabType1
            this.tabContent = this.drugInfo.tabContent1

            this.isTabActive1 = true
            this.isTabActive2 = false
            this.isTabActive3 = false

            this.showDetail = true
            if (this.drugInfo.isComment) this.$refs.commentForm.fetchComment(this.liveInfoId, this.contentType)
          } else {
            callExternalApi("LinkUrl", rs.data.item.externalLink)
            this.closeDrugInfoModel()
          }
          setTimeout(() => {
            this.scrollTop()
          }, 0)
        })
        .catch(() => {
          this.showAlertDialog('조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    openDrugInfoModal(id, innerCall = false) {
      this.drugInfo = null
      this.liveInfoId = id
      this.medicalBannerLogging = false // 컨텐츠 변경 시 배너 로깅 유무 초기화

      this.fetchDrugInfo(id)
      this.updateViewCount(id)
      if (innerCall) this.$emit('statUpdate', id)

      this.comment.commentText = ''
      this.comment.replyEditText = ''
      this.comment.isEditing = false
      this.comment.isReplyEditing = false
    },
    updateViewCount(id) {
      axios.patch(`/fu/drugInfo/UpdateViewCount/${id}`).catch(() => {})
    },
    closeDrugInfoModel() {
      this.showDetail = false
      this.$emit('fetchDrugInfos')
    },
    getDateString(dateStr) {
      const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
      const date = new Date(dateStr)
      const dayOfWeek = daysOfWeek[date.getDay()]

      return `${dayOfWeek}`
    },
    scrollTop() {
      const detailWrap = document.querySelectorAll(".medical-detail-wrap")

      detailWrap.forEach(item => {
        item.scrollTo({ top: 0, behavior: 'instant' })
      })
    },
    scrollToElement() {
      const location = document.querySelector(".md-reply").offsetTop
      const value = location - 144
      const detailWrap = document.querySelectorAll(".medical-detail-wrap")

      detailWrap.forEach(item => {
        item.scrollTo({ top: value, behavior: 'smooth' })
      })
    },
    scrollPopup() {
      const detailWrap = document.querySelector(".medical-detail-wrap")

      if (!this.medicalBannerLogging && (detailWrap.clientHeight + 200 > detailWrap.scrollHeight - detailWrap.scrollTop)) {
        // 콘텐츠 스크롤이 메디컬트렌드 배너가 보이는 위치에 도달했을 때, 메디컬트렌드 배너 노출 수 로깅
        this.medicalBannerLogging = true
        if (this.medicalFixBanner && this.medicalFixBanner.length > 0) setStatistics(180, this.medicalFixBanner[0].liveInfoId)
        if (this.medicalFixBanner && this.medicalFixBanner.length > 1) setStatistics(180, this.medicalFixBanner[1].liveInfoId)
      }
    },
    getNextId(currentId) {
      const currentIndex = this.liveInfoIds.indexOf(currentId)
      const nextIndex = (currentIndex + 1) % this.liveInfoIds.length

      return this.liveInfoIds[nextIndex]
    },
    getPrevId(currentId) {
      const currentIndex = this.liveInfoIds.indexOf(currentId)
      const prevIndex = (currentIndex - 1 + this.liveInfoIds.length) % this.liveInfoIds.length

      return this.liveInfoIds[prevIndex]
    },
    moveNext() {
      const nextId = this.getNextId(this.liveInfoId)
      this.openDrugInfoModal(nextId, true)
    },
    movePrev() {
      const prevId = this.getPrevId(this.liveInfoId)
      this.openDrugInfoModal(prevId, true)
    },
    drugInfoTab1() {
      this.tabType = this.drugInfo.tabType1
      this.tabContent = this.drugInfo.tabContent1

      this.isTabActive1 = true
      this.isTabActive2 = false
      this.isTabActive3 = false
    },
    drugInfoTab2() {
      this.tabType = this.drugInfo.tabType2
      this.tabContent = this.drugInfo.tabContent2

      this.isTabActive1 = false
      this.isTabActive2 = true
      this.isTabActive3 = false
    },
    drugInfoTab3() {
      this.tabType = this.drugInfo.tabType3
      this.tabContent = this.drugInfo.tabContent3

      this.isTabActive1 = false
      this.isTabActive2 = false
      this.isTabActive3 = true
    },
    async checkedLogin() {
      const isLoggedIn = await isUserLoggedIn()

      if (!isLoggedIn) {
        this.$router.push({
          name: 'login-main',
          query: { redirect: this.$route.name },
        })
      }
    },
    handleClick(event) {
      // 클릭 이벤트가 발생한 요소의 ID를 확인
      const targetId = event.target.id

      switch (targetId) {
        case 'drinfo-rfc-visit-call':
          this.visitCall()
          break
        case 'drinfo-rfc-sample-call':
          this.sampleCall()
          break
        case 'drinfo-rfc-email-call':
          this.emailCall()
          break
        case 'drinfo-rfc-external-link':
          this.callExternalLink()
          break
        default:
          break
      }
    },
    tabHandleClick(event) {
      if (event.target.localName === 'a') {
        const href = event.target.attributes.href.value
        callExternalApi("LinkUrl", href)

        event.preventDefault()
      } else if (event.target.parentElement.localName === 'a') {
        const href = event.target.parentElement.attributes.href.value
        callExternalApi("LinkUrl", href)
        event.preventDefault()
      }
    },
    visitCall() {
      this.checkedLogin()
      this.showVisitModal = true
      this.$refs.visitCallRef.fetchDrugAsk()
    },
    sampleCall() {
      this.checkedLogin()
      this.showSampleModal = true
      this.$refs.sampleCallRef.fetchDrugAsk()
    },
    emailCall() {
      this.checkedLogin()
      this.showEmailModal = true
      this.$refs.emailCallRef.fetchDrugAsk()
    },
    closeVisitModal() {
      this.showVisitModal = false

      this.$refs.visitCallRef.terms1 = false
      this.$refs.visitCallRef.terms2 = false
      this.$refs.visitCallRef.agreeAll = false
    },
    closeSampleModal() {
      this.showSampleModal = false
    },
    closeEmailModal() {
      this.showEmailModal = false
    },
  },
}
</script>

<style>
.content img {
  max-width: 100%;
}
</style>
